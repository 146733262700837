html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
}

.content-wrapper {
  margin-top: 80px; /* Prevent content from being hidden behind the fixed navbar */
}

.card-link-wrapper {
  text-decoration: none;
}

.card-img-container {
  position: relative;
}

.card-img-top {
  transition: 0.3s ease;
}

/* The overlay that appears on hover */
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-overlay {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: bold;
  color: white;
  padding: 10px;
  z-index: 1;  /* Ensure text is on top of image */
}

/* Hover effect to make the overlay visible */
a:hover .overlay {
  opacity: 1;
}

/* Optional: Center the logos with some spacing */
.row {
  margin-bottom: 20px;
}

.col-4 {
  display: flex;
  justify-content: center;
}

/* Ensure all tiles are the same size and aligned */
.d-flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.tile {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Hover effect for Tile */
a:hover .overlay {
  opacity: 1;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
}

/* Prevent content from hiding behind fixed navbar */
.content-wrapper {
  margin-top: 80px;
}

/* Light Theme Styles */
.light-theme {
  background-color: #ffffff;
  color: #333333;
}

/* Dark Theme Styles */
.dark-theme {
  background-color: #121212;
  color: #ffffff;
}

/* You can override additional Bootstrap classes if needed */
.light-theme .navbar {
  background-color: #f8f9fa !important;
}

.dark-theme .navbar {
  background-color: #333333 !important;
}

/* Ensure links, headings, etc. adjust properly */
.light-theme a {
  color: #007bff;
}

.dark-theme a {
  color: #66b2ff;
}

